import { usePathname } from 'next/navigation';

import { AxiosError } from 'axios';

import { datadogLogs } from '@datadog/browser-logs';

export enum LOG_TYPE {
  INFO = 'info',
  ERROR = 'error',
  CRITICAL = 'critical',
}

type LogParams = {
  name: string;
  props?: object;
  error?: Error;
};

const buildLogMessage = (log: string, pathname: string, error: string) => {
  return `
    Message: ${log}
    Path: ${pathname}
    Error: ${error}
  `;
};

const getErrorMessage = (error: Error | undefined) => {
  if (!error) return '';

  if (error instanceof AxiosError) {
    if (error.response?.data?.message) {
      return error.response?.data?.message;
    }
  }

  return error.message;
};

export const useLogs = () => {
  const pathname = usePathname();

  const log = (params: LogParams, type: LOG_TYPE) => {
    const error = getErrorMessage(params.error);

    switch (type) {
      case LOG_TYPE.INFO:
        datadogLogs.logger.info(params.name, params.props);
        break;
      case LOG_TYPE.ERROR:
        datadogLogs.logger.error(buildLogMessage(params.name, pathname, error), params.props, params.error);
        break;
      case LOG_TYPE.CRITICAL:
        datadogLogs.logger.critical(buildLogMessage(params.name, pathname, error), params.props, params.error);
        break;

      default:
        break;
    }
  };

  return { log };
};
