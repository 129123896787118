import type { AppProps } from 'next/app';
import { Montserrat, Open_Sans } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { twMerge } from 'tailwind-merge';

import { datadogLogs } from '@datadog/browser-logs';

import { ClerkProvider } from '@clerk/nextjs';

import AppProvider from '@/context/app-provider';
import SessionProvider from '@/context/session-provider';
import '@/styles/globals.css';

import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import Hotjar from '@hotjar/browser';

import { env } from '@/config/env';

import { isProduction } from '@/utils';

import NetworkStatus from '@/components/network-status';

const montserrat = Montserrat({ subsets: ['latin'] });
const openSans = Open_Sans({ subsets: ['latin'], variable: '--font-sans' });

if (isProduction && env.DATADOG_LOGS_CLIENT_TOKEN)
  datadogLogs.init({
    clientToken: env.DATADOG_LOGS_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'tradein-web',
    version: '1.0.0',
  });

if (isProduction && env.HOTJAR_SITE_ID && env.HOTJAR_VERSION)
  Hotjar.init(Number(env.HOTJAR_SITE_ID), Number(env.HOTJAR_VERSION));

if (env.AMPLITUDE_API_KEY && isProduction) {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.5,
  });

  amplitude.init(env.AMPLITUDE_API_KEY, {
    autocapture: {
      pageViews: true,
    },
  });

  amplitude.add(sessionReplayTracking);
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { pathname } = useRouter();
  const isSession = pathname.includes('/session');

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=0, viewport-fit=cover"
        />
        <meta name="application-name" content="Doji Trade-In" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Doji Trade-In" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#000000" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <NetworkStatus />

      {env.GOOGLE_ANALYTICS_ENABLE && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=${env.GOOGLE_ANALYTICS_ID}`}
          />
          <Script id="ga-integration" strategy="lazyOnload">
            {`
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${env.GOOGLE_ANALYTICS_ID}', {
					page_path: window.location.pathname,
					});
				`}
          </Script>
        </>
      )}

      {env.CRISP_WEBSITE_ID && (
        <Script id="crisp-integration" strategy="lazyOnload">
          {`
					window.$crisp=[];
					window.CRISP_WEBSITE_ID="${env.CRISP_WEBSITE_ID}";
					(function(){d=document;s=d.createElement("script");
					s.src="https://client.crisp.chat/l.js";
					s.async=1;
					d.getElementsByTagName("head")[0].appendChild(s);})();
				`}
        </Script>
      )}

      {isSession && (
        <SessionProvider>
          <main className={twMerge('min-w-screen', montserrat.className, openSans.variable)}>
            <Component {...pageProps} />
          </main>
        </SessionProvider>
      )}

      {!isSession && (
        <ClerkProvider>
          <AppProvider>
            <main className={twMerge('min-w-screen', montserrat.className, openSans.variable)}>
              <Component {...pageProps} />
            </main>
          </AppProvider>
        </ClerkProvider>
      )}
    </>
  );
};

export default MyApp;
