import { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import StoreOperatorService from '@@/StoreAndStoreOperator/services/store-operator-service';

import { LOG_TYPE, useLogs } from '@/hooks/use-logs';

import { fetchKeys } from '@/constants/fetch-keys';

export const useStoreOperatorService = () => {
  const { log } = useLogs();
  const {
    data,
    refetch: refetchStoreOperator,
    isSuccess: isStoreOperatorSuccess,
    isError: isStoreOperatorError,
    isRefetchError,
  } = useQuery({
    queryKey: [fetchKeys.user.data],
    queryFn: () => StoreOperatorService.getMe(),
    enabled: false,
  });

  useEffect(() => {
    if (isStoreOperatorError || isRefetchError) {
      log(
        {
          name: `Error fetching store operator data ${data?.message}`,
        },
        LOG_TYPE.CRITICAL
      );
    }
  }, [isStoreOperatorError, isRefetchError]);

  return { refetchStoreOperator, storeOperatorMeData: data?.response, isStoreOperatorSuccess };
};
