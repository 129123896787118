import axios from 'axios';

import { datadogLogs } from '@datadog/browser-logs';

import { env } from './env';

const headers = {
  'Accept-Language': 'pt-BR',
  country: 'BR',
  currency: 'BRL',
};

export const tradeInApi = axios.create({
  baseURL: env.TRADE_IN_BACKEND_ADDRESS || 'https://api.tradein.staging.doji.com.br',
  headers: headers,
});

tradeInApi.interceptors.response.use((req) => {
  if (req.status === 500) {
    try {
      datadogLogs.logger.critical('Critical error 500:', req.data);
    } catch (error) {
      console.error('Critical error 500:', error);
    }
  }
  return req;
});
