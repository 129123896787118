import { memo, useEffect } from 'react';

import { useNetworkStatus } from '@/hooks/use-network-status';

const NetworkStatus = () => {
  const { isOnline, retryCount, isRetrying, setIsRetrying } = useNetworkStatus();

  useEffect(() => {
    let retryTimeout: NodeJS.Timeout;

    if (isRetrying) {
      retryTimeout = setTimeout(() => {
        setIsRetrying(false);
      }, 2000);
    }

    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [isRetrying, setIsRetrying]);

  if (!isOnline || isRetrying) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
        <div className="mx-4 max-w-md rounded-lg bg-white p-6 text-center shadow-lg">
          <h2 className="mb-4 text-xl font-bold text-doji-green">
            {isRetrying ? 'Reconectando...' : 'Sem conexão com a internet'}
          </h2>
          <p className="mb-4 text-gray-600">
            {isRetrying
              ? 'Verificando conexão com a internet...'
              : 'Por favor, verifique sua conexão com a internet e tente novamente.'}
          </p>
          <div className="flex justify-center">
            <div className="h-4 w-4 animate-spin rounded-full border-4 border-doji-green border-t-transparent"></div>
          </div>
          {retryCount > 0 && <p className="mt-2 text-sm text-gray-500">Tentativa de reconexão: {retryCount}</p>}
        </div>
      </div>
    );
  }

  return null;
};

export default memo(NetworkStatus);
