import { useCallback, useEffect, useState } from 'react';

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);

  const handleOnline = useCallback(() => {
    setIsOnline(true);
    setIsRetrying(true);
    setRetryCount(0);
  }, []);

  const handleOffline = useCallback(() => {
    setIsOnline(false);
    setIsRetrying(false);
  }, []);

  useEffect(() => {
    setIsOnline(navigator.onLine);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [handleOnline, handleOffline]);

  const incrementRetryCount = useCallback(() => {
    setRetryCount((prev) => prev + 1);
  }, []);

  return {
    isOnline,
    retryCount,
    isRetrying,
    setIsRetrying,
    incrementRetryCount,
  };
};
