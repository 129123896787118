import { ReactNode, memo } from 'react';

import { twMerge } from 'tailwind-merge';

import Spinner from '@/components/spinner';

type Props = {
  isVisible?: boolean;
  children?: ReactNode;
  className?: string;
};

const FullScreenSpinner = ({ isVisible = true, children, className }: Props) => {
  if (!isVisible) return null;

  return (
    <div data-testid="full-screen-spinner" className="fixed top-0 left-0 z-50 h-full w-full">
      <div className={twMerge('absolute h-full w-full bg-black opacity-70', className)} />
      <div className="mt-[50vh] flex flex-col items-center justify-center">
        <div>
          <Spinner size="xl" className="translate-y-[-50%]" fill="fill-white" />
        </div>

        {children}
      </div>
    </div>
  );
};

export default memo(FullScreenSpinner);
