import { ApiResponse } from '@/@types/api-response';

import { CosmeticGradingResponse } from '@@/FunctionalAndCosmetic/entities/cosmetic-grading';
import { PHONE_PERSPECTIVE } from '@@/ItemAndGrading/constants/phone-perspective';
import { ItemImage } from '@@/ItemAndGrading/entities/file';
import { FunctionalGrading, Item } from '@@/ItemAndGrading/entities/item';

import { tradeInApi } from '@/config/api';

type MissingImages = {
  screenOn: number;
  front: number;
  back: number;
  lateral: number;
};

export type UploadResponse = {
  image: {
    imageId: string;
    perspective: `${PHONE_PERSPECTIVE}`;
    url: string;
  };
  missingImages: MissingImages;
};

export type GetImagesResponse = {
  itemId: string;
  images: Array<{
    image: ItemImage;
    perspective: PHONE_PERSPECTIVE;
  }>;
  missingImages: MissingImages;
};

export type DeleteResponse = {
  imageId: string;
  missingImages: MissingImages;
};

export default class ItemService {
  static async uploadImage(itemId: string, step: `${PHONE_PERSPECTIVE}`, file: File) {
    const form = new FormData();

    form.append('image', file);
    form.append('perspective', step);

    const { data } = await tradeInApi.put<ApiResponse<UploadResponse>>(`/v1/secure/items/${itemId}/images`, form);
    return data;
  }

  static async getImages(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<GetImagesResponse>>(`/v1/secure/items/${itemId}/images`);

    return data;
  }

  static async deleteImage(itemId: string, fileId: string) {
    const { data } = await tradeInApi.delete<ApiResponse<DeleteResponse>>(
      `/v1/secure/items/${itemId}/images/${fileId}`
    );

    return data;
  }

  static async postTriggerAICosmeticGrade(itemId: string) {
    const { data } = await tradeInApi.post<ApiResponse<null>>(`/v1/secure/items/${itemId}/images/confirm`);

    return data;
  }

  static async findItem(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<Item>>(`/v1/secure/items/${itemId}`);

    return data;
  }

  static async findItemSession(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<Item>>(`/v1/session/items/${itemId}`);

    return data;
  }

  static async createItem(productId: string) {
    const { data } = await tradeInApi.post<ApiResponse<Item>>(`/v1/secure/items`, { productId: productId });

    return data;
  }

  static async createItemSession(productId: string) {
    const { data } = await tradeInApi.post<ApiResponse<Item>>(`/v1/session/items`, { productId: productId });

    return data;
  }

  static async createPhonecheckCode(itemId: string, forceRecreate: boolean = false) {
    const { data } = await tradeInApi.post<ApiResponse<FunctionalGrading>>(
      `/v1/secure/items/${itemId}/gradings/functional?forceRecreate=${forceRecreate}`
    );
    return data;
  }

  static async getPhonecheckReport(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<FunctionalGrading>>(
      `/v1/secure/items/${itemId}/gradings/functional`
    );
    return data;
  }

  static async updateFunctionalGrading(itemId: string, body: { ignored?: string[]; skipComment?: string }) {
    const { data } = await tradeInApi.put<ApiResponse<FunctionalGrading>>(
      `/v1/secure/items/${itemId}/gradings/functional`,
      body
    );
    return data;
  }

  static async updateGradingCosmetic(itemId: string, body: { reviewedResults: { imageId: string; result: string }[] }) {
    const { data } = await tradeInApi.put<ApiResponse<CosmeticGradingResponse>>(
      `/v1/secure/items/${itemId}/gradings/cosmetic`,
      body
    );
    return data;
  }

  static async findSessionItem(itemId: string) {
    const { data } = await tradeInApi.get<ApiResponse<Item>>(`/v1/session/items/${itemId}`);

    return data;
  }

  static async sessionCreate(productId: string) {
    const { data } = await tradeInApi.post<ApiResponse<Item>>(`/v1/session/items`, { productId: productId });

    return data;
  }
}
