import { isNil } from 'ramda';

import { LOG_TYPE, useLogs } from './use-logs';

export enum STORAGE_KEYS {
  GOOGLE_LOGIN = '@Doji/google-login',
  DOJI_USER_DATA = '@Doji/user-data',
  SELECTED_STORE = '@Doji/selected-store',
  DAILY_TIP_LAST_VIEWED = '@Doji/daily-tip-last-viewed',
  DOJI_TRADE_IN_ID = '@Doji/trade-in-id',
  DOJI_ITEM_ID = '@Doji/item-id',
  DOJI_MODEL_ID = '@Doji/model-id',
  DOJI_SURVEY_ANSWERS = '@Doji/survey-answers',
  DOJI_CUSTOMER_REF_ID = '@Doji/customer-ref-id',
  DOJI_BUY_NEW_PRODUCT = '@Doji/buy-new-product',
  DOJI_CUSTOMER_FORMS = '@Doji/customer-forms',
  DOJI_PRODUCT_NAME = '@Doji/product-name',
  SESSION_DATA = '@Doji/session-data',
  ASSESSMENT_DATA = '@Doji/assessment-data',
  PHONE_PHOTO_INSTRUCTIONS_MODAL = '@Doji/phone-photo-instructions-modal',
  DOJI_TRADE_IN_SESSION = '@Doji/trade-in-session',
  DOJI_ORIGINAL_PRICE = '@Doji/original-price',
}

export const useLocalStorage = () => {
  const { log } = useLogs();

  if (typeof window === 'undefined') return;

  const setItem = (key: string, value: unknown) => {
    if (isNil(value)) return;

    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      log({ name: 'Error setting local storage item', error: error as Error }, LOG_TYPE.CRITICAL);
    }
  };

  const getItem = (key: string) => {
    try {
      return JSON.parse(localStorage.getItem(key) as string);
    } catch (error) {
      log({ name: 'Error getting local storage item', error: error as Error }, LOG_TYPE.CRITICAL);
      return null;
    }
  };

  const removeItem = (key: string) => localStorage.removeItem(key);

  const clearStorage = () => localStorage.clear();

  const hasItem = (key: string) => localStorage.getItem(key) !== null;

  return { setItem, getItem, removeItem, clearStorage, hasItem };
};
