import React, { createContext, useContext, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { includes } from 'ramda';

import { useQueryClient } from '@tanstack/react-query';

import { useAuth, useSession } from '@clerk/nextjs';

import type { Session } from '@@/Authentication/entities/session';
import { useVerifyPermission } from '@@/Authentication/hooks';
import { CLERK_TEMPLATES } from '@@/Authentication/integration/clerk';
import { Store } from '@@/StoreAndStoreOperator/entities/store';
import { StoreOperator } from '@@/StoreAndStoreOperator/entities/store-operator';
import { useFindStore, useStoreOperatorService } from '@@/StoreAndStoreOperator/hooks';

import { STORAGE_KEYS, useLocalStorage } from '@/hooks/use-local-storage';
import { LOG_TYPE, useLogs } from '@/hooks/use-logs';

import { tradeInApi } from '@/config/api';

import { ROUTES } from '@/constants';
import { fetchKeys } from '@/constants/fetch-keys';

import { isEmptyOrNil } from '@/utils';
import { isCustomerTradeInRoute, isPublicRoute } from '@/utils/routes-validators';

export type UserData = Pick<StoreOperator, 'firstName' | 'lastName' | 'userId' | 'document' | 'id'>;

export type UserAuthContextData = {
  logout: () => void;
  userData?: UserData;

  storeData?: Store;
  saveStoreData: (store: Store) => void;

  isSignedIn?: boolean;
  cleanLegacyData: () => void;
  cleanSessionTradeIn: () => void;

  interceptorId: number | null;

  sessionTradeIn?: Session;
  setSessionTradeInAsync: (session: Session) => Promise<number>;

  resetStoreData: () => void;
};

const UserAuthContext = createContext<UserAuthContextData>({} as UserAuthContextData);

export const UserAuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { log } = useLogs();
  const router = useRouter();
  const queryClient = useQueryClient();
  const storage = useLocalStorage();
  const { isSignedIn: isClerkSessionSignedIn } = useSession();

  const { isOnlyAdmin } = useVerifyPermission();
  const { isLoaded, isSignedIn: isClerkSignedIn, getToken, signOut, sessionId } = useAuth();

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [interceptorId, setInterceptorId] = useState<number | null>(null);

  const [userData, setUserData] = useState<UserData>(() => {
    return storage?.getItem(STORAGE_KEYS.DOJI_USER_DATA);
  });
  const [storeData, setStoreData] = useState<Store>(() => {
    return storage?.getItem(STORAGE_KEYS.SELECTED_STORE);
  });
  const [sessionTradeIn, setSessionTradeIn] = useState<Session>(() => {
    return storage?.getItem(STORAGE_KEYS.DOJI_TRADE_IN_SESSION);
  });

  const hasClerkToken = Boolean(isLoaded && isClerkSignedIn);
  const shouldBeLogged = isLoaded && !isPublicRoute(router.pathname) && !isCustomerTradeInRoute(router.pathname);

  const isOperatorPage = includes(router.pathname, [ROUTES.OPERATOR_PORTAL]);

  const { isStoreOperatorSuccess, storeOperatorMeData, refetchStoreOperator } = useStoreOperatorService();
  const {
    storeData: currentStore,
    isStoreSuccess,
    refetchStore,
  } = useFindStore({
    storeId: storeData?.id,
    enabled: Boolean(
      shouldBeLogged && isClerkSignedIn && hasClerkToken && !!storeData?.id && isStoreOperatorSuccess && !isOnlyAdmin
    ),
    refetchInterval: 15 * 1000,
  });

  useEffect(() => {
    if (sessionTradeIn && !hasClerkToken) {
      storage?.setItem(STORAGE_KEYS.DOJI_TRADE_IN_SESSION, sessionTradeIn);
    }
  }, [sessionTradeIn]);

  useEffect(() => {
    if (isOnlyAdmin) router.push(ROUTES.ONLY_ADMIN);
  }, [isOnlyAdmin]);

  useEffect(() => {
    if (isClerkSignedIn || !isEmptyOrNil(sessionTradeIn)) {
      setIsSignedIn(true);
      return;
    }

    if (shouldBeLogged) logout();

    setIsSignedIn(false);
  }, [isClerkSignedIn, sessionId, isClerkSessionSignedIn]);

  useEffect(() => {
    if (storeOperatorMeData && isStoreOperatorSuccess) {
      const userData = {
        id: storeOperatorMeData.id,
        firstName: storeOperatorMeData.firstName,
        lastName: storeOperatorMeData.lastName,
        userId: storeOperatorMeData.userId,
        document: storeOperatorMeData.document,
      } as UserData;

      storage?.setItem(STORAGE_KEYS.DOJI_USER_DATA, userData);
      setUserData(userData);
    }
  }, [storeOperatorMeData, isStoreOperatorSuccess]);

  useEffect(() => {
    if (isStoreSuccess && !isOperatorPage) {
      if (currentStore) {
        setStoreData(currentStore);
        storage?.setItem(STORAGE_KEYS.SELECTED_STORE, currentStore);
        return;
      }

      if (!isOnlyAdmin && router.pathname !== ROUTES.OPERATOR_PORTAL) {
        setStoreData({} as Store);
        storage?.removeItem(STORAGE_KEYS.SELECTED_STORE);
        router.push(ROUTES.OPERATOR_PORTAL);
      }
    }
  }, [currentStore, isStoreSuccess, storeData?.id]);

  useEffect(() => {
    const setInterceptor = async () => {
      await handleSetInterceptor(storeData);

      if (shouldBeLogged && hasClerkToken && !isOnlyAdmin) {
        refetchStoreOperator();
        if (storeData?.id) refetchStore();
      }
    };

    setInterceptor();
  }, [storeData?.id, hasClerkToken]);

  const handleSetInterceptor = (store: Store, session?: Session): Promise<number> =>
    new Promise((resolve, reject) => {
      if (interceptorId) tradeInApi.interceptors.request.clear();

      const interceptor = tradeInApi.interceptors.request.use(async (req) => {
        if (shouldBeLogged && hasClerkToken) {
          try {
            const newToken = await getToken({ template: CLERK_TEMPLATES.DEFAULT });
            req.headers['Authorization'] = `Bearer ${newToken}`;
          } catch (error) {
            log({ name: 'Error getting token', error: error as Error }, LOG_TYPE.ERROR);
            reject(error);
          }
        }

        const sessionToken = session?.token ?? sessionTradeIn?.token;
        if (sessionToken) {
          try {
            req.headers['Authorization'] = `Bearer ${sessionToken}`;
          } catch (error) {
            log({ name: 'Error getting session token', error: error as Error }, LOG_TYPE.ERROR);
            reject(error);
          }
        }

        if (store) {
          req.headers['Doji-Store-Id'] = store.id;
          req.headers['Doji-Org-Id'] = store.orgId;
        }

        return req;
      });

      setInterceptorId(interceptor);
      resolve(interceptor);
    });

  const cleanLegacyData = () => {
    tradeInApi.defaults.headers['Authorization'] = '';

    setUserData({} as UserData);
    setStoreData({} as Store);
    setSessionTradeIn({} as Session);
    setIsSignedIn(false);

    //persist data tip even in logout
    const lastViewed = storage?.getItem(STORAGE_KEYS.DAILY_TIP_LAST_VIEWED);
    const phonePhotosModal = storage?.getItem(STORAGE_KEYS.PHONE_PHOTO_INSTRUCTIONS_MODAL);

    storage?.clearStorage();

    //persist data tip even in logout
    storage?.setItem(STORAGE_KEYS.DAILY_TIP_LAST_VIEWED, { ...lastViewed });
    storage?.setItem(STORAGE_KEYS.PHONE_PHOTO_INSTRUCTIONS_MODAL, { ...phonePhotosModal });

    queryClient.invalidateQueries({ queryKey: [fetchKeys.store.authStoreData, fetchKeys.user.data] });
    queryClient.clear();
  };

  const saveStoreData = (store: Store) => {
    if (hasClerkToken) handleSetInterceptor(store);

    setStoreData(store);
    storage?.setItem(STORAGE_KEYS.SELECTED_STORE, store);
  };

  const resetStoreData = () => {
    setStoreData({} as Store);
    storage?.removeItem(STORAGE_KEYS.SELECTED_STORE);
    handleSetInterceptor(storeData);
  };

  const logout = async () => {
    const hasClerk = hasClerkToken;

    queryClient.invalidateQueries({ queryKey: [fetchKeys.user.data] });

    cleanLegacyData();

    if (hasClerk) {
      tradeInApi.interceptors.request.clear();
      await signOut({ redirectUrl: ROUTES.LOGIN });
      return;
    }

    if (router.pathname !== ROUTES.LOGIN) router.replace(ROUTES.LOGIN, undefined, { shallow: true });
  };

  const cleanSessionTradeIn = () => {
    tradeInApi.interceptors.request.clear();
    setSessionTradeIn({} as Session);
  };

  const setSessionTradeInAsync = async (session: Session) => {
    setSessionTradeIn(session);
    return await handleSetInterceptor(storeData, session);
  };

  const value = {
    isSignedIn,
    logout,
    userData,
    storeData,
    saveStoreData,
    cleanLegacyData,
    cleanSessionTradeIn,
    interceptorId,
    sessionTradeIn,
    setSessionTradeInAsync,
    resetStoreData,
  };

  if (!isLoaded || (isSignedIn && interceptorId === null)) return null;

  return <UserAuthContext.Provider value={value}>{children}</UserAuthContext.Provider>;
};

export const useUserAuth = (): UserAuthContextData => {
  const context = useContext(UserAuthContext);
  return context;
};
