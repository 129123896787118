import { includes } from 'ramda';

import { PUBLIC_ROUTES, ROUTES_AFTER_AUCTION } from '@/constants';

export const isPublicRoute = (pathname: string) => includes(pathname, PUBLIC_ROUTES);

export const isRouteAfterAuction = (pathname: string) => includes(pathname, ROUTES_AFTER_AUCTION);

export const isCustomerTradeInRoute = (pathname: string) => {
  if (pathname) {
    return !!pathname.match(/^\/customer\/?(?![\w-])/);
  }

  return false;
};
