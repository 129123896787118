import * as R from 'ramda';

import { DeflatorAndDefectData, Item } from '@@/ItemAndGrading/entities/item';
import { STORE_TEST_UTILS_ENABLED_IN_PROD } from '@@/StoreAndStoreOperator/constants';

import { env } from '@/config/env';

import { PARAM_ORIGIN, ROUTES } from '@/constants';

export * from './google-analytics';
export * from './strings';

export const isEmptyOrNil = R.anyPass([R.isEmpty, R.isNil]);

export const concatMessageId = (prefix: string, text: string) => R.concat(prefix, text);

export const isNotEmpty = R.complement(R.isEmpty);

export const filterAIResults = R.filter(
  R.allPass([
    R.complement(R.propEq('SCRATCHED', 'result')),
    R.complement(R.propEq('CLEAN', 'result')),
    R.complement(R.propEq('NOT_GRADED', 'result')),
  ])
);

export const getRouteWithOrigin = (route: ROUTES, origin: PARAM_ORIGIN) => `${route}?origin=${origin}`;

export const getDefectsNameAsString = (defects?: DeflatorAndDefectData[]) => {
  if (!defects) return '';

  return R.join(
    ', ',
    R.map((defect) => defect.name, defects)
  );
};

export const getDefectsNameFromId = (defect: string, item: Item) => {
  const defects = item.grading.defects;
  const defectFound = R.find(R.propEq(defect, 'id'), defects);
  return defectFound ? defectFound.name : ' ';
};

export const isTestUtilsEnabled = (storeId?: string) =>
  isDevOrLocal || (isProduction && R.includes(storeId, STORE_TEST_UTILS_ENABLED_IN_PROD));

export const isDevOrLocal = R.includes(env.DOJI_ENV, ['development', 'localhost']);

export const isLocalHost = R.equals(env.DOJI_ENV, 'localhost');

export const isProduction = R.equals(env.DOJI_ENV, 'production');
